.site-footer {
  position: relative; }

.footer {

  &-bottom {

    &__logo {
      img {
        max-height: 25px;
        width: auto; } } } }


.section-footer-link {
  cursor: pointer; }
