.popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 99999;

  &:target {
    visibility: visible;
    opacity: 1; } }

.popup {
  margin: 70px auto;
  padding: 0px;
  background: #fff;
  border-radius: 5px;
  width: 37%;
  @media (min-width: 681px) and (max-width: 1024px) {
    width: 50%; }
  @media (min-width: 483px) and (max-width: 680px) {
    width: 65%; }
  @media screen and (max-width: 482px) {
    width: 94%; }
  position: relative;
  transition: all 5s ease-in-out;

  .close {
    position: absolute;
    top: -40px;
    right: -30px;
    @media screen and (max-width: 482px) {
      top: -44px;
      right: -4px; }
    transition: all 200ms;
    font-size: 38px;
    font-weight: bold;
    text-decoration: none;
    color: #fefefe;
    z-index: 999999;

    &:hover {
      color: #C21717; } }

  .content {
    height: 400px;
    max-height: 30%;
    overflow: auto; }

  .btn {
    padding: 6px 11px; } }

#form-popup {
  .popup {
    padding: 40px 20px; } }
