@import url('https://fonts.googleapis.com/css?family=Fira+Sans');

body {
  font-family: 'Fira Sans', sans-serif; }

.home-banner__video {
  opacity: 0.2; }

.home-banner__title {
  width: 78%;
  white-space: pre-wrap;

  @media (max-width: 480px) {
    width: 96%;
    font-size: 28px; } }

.logo-block .make-square__space.make-square__space--almost-half {
  @media (max-width: 975px) {
    margin-top: 22%; } }

.logo-block__image {
  min-height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1920px) {
    min-height: 112px; }

  .img-responsive {
    @media (min-width: 976px) and (max-width: 1182px) {
      max-height: 81px; }

    @media (max-width: 750px) {
      max-height: 100px; } } }

#about .parallax-bg {
  @media (min-width: 1024px) {
    background-position: center -100px; } }

#about .block--full {
  height: 40vh;
  margin-bottom: 40px; }

#about .block-full-wrap {
  width: 75%;
  margin: 0 auto; }

h2.box__title_purple {
  text-transform: uppercase;
  color: #4b1a7d; }

.about__items-wrap, .about__certificates-wrap, .about__reviews-wrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px; }

.about__item {
  flex-basis: 30%;
  border-left: 2px solid #4b1a7d;
  padding-left: 13px;
  margin-bottom: 20px;
  min-height: 91px;
  font-size: 18px;

  @media (max-width: 767px) {
    flex-basis: 100%; } }

.about__item-title {
  font-size: 24px;
  font-weight: 700;
  color: #4b1a7d; }

.about__item-text {
  margin: 0;
  font-weight: 700; }

.about__certificate {
  flex-basis: 30%;
  margin: 10px 0;

  @media (max-width: 767px) {
    flex-basis: 100%; } }

.about__certificate img, .about__review img {
  width: 100%; }

.about__review img {
  max-width: 1240px; }

.about__review {
  display: flex;
  align-items: flex-start;
  flex-basis: 100%;
  margin: 15px 0; }

.about__desc {
  text-align: justify;
  margin: 55px 0;
  font-size: 1.6em;
  line-height: 1.5;

  @media (max-width: 767px) {
    margin: 15px 0 55px;
    font-size: 1.2em; } }

.about__team {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 25px 0; }

.about__member {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;

  @media (min-width: 1920px) {
    margin-bottom: 40px; }

  @media (max-width: 767px) {
    flex-basis: 100%; }

  .about__member-img {
    text-align: center;
    padding: 0 30px;

    @media (min-width: 480px) and (max-width: 767px) {
      padding: 0 70px; }

    img {
      width: 100%;
      border-radius: 50%; } }

  .about__member-name {
    margin: 17px 0 10px;
    font-size: 18px;
    font-weight: 700;

    @media (min-width: 1920px) {
      font-size: 23px; } }

  .about__member-position {
    text-align: center;

    @media (min-width: 1920px) {
      font-size: 18px; } } }

.violet-text {
  color: #4b1a7d; }

.project-block {
  @media screen and (max-width: 760px) {
    min-height: 70px !important; }

  .project-block__content {
    cursor: pointer;

    @media screen and (min-width: 761px) {
      opacity: 0; } } }

.project-block:hover .project-block__content {
  @media screen and (min-width: 761px) {
    opacity: 1; } }

.project-blocks--case-studies {
  .project-block__title {
    opacity: 1; } }

.service-block {
  min-height: 230px !important;

  @media (max-width: 480px) {
    min-height: 214px !important; }

  .service-block__content-wrap {
    padding-left: 12px;
    padding-right: 12px;

    .service-block__subtitle {
      white-space: normal; } } }

.logo-blocks {
  min-height: 365px !important;
  margin-bottom: 30px;

  .logo-block {

    .logo-block__image {
      @media (max-width: 767px) {
        padding: 20px;
        max-width: 80%;
        margin: 0 auto; }

      .img-responsive {
        @media (max-width: 767px) {
          margin: 0 auto; } } } } }

.cta {
  @media (min-width: 768px) {
    height: 450px; }

  @media (max-width: 767px) {
    height: 542px; } }

.cta__content-wrap {
  padding-left: 0 !important;
  padding-right: 0 !important;
  top: 55% !important;
  height: 100%;

  .cta__content-row {
    display: flex;
    margin-top: 30px;
    background-color: #fff;

    @media (max-width: 767px) {
      flex-direction: column-reverse; }

    .cta__content-map, .cta__content-text {
      flex-basis: 50%; }

    .cta__content-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 15px 30px;
      text-align: left; } } }

@import 'loader';
@import 'parallax';
@import 'navbar';
@import 'sidebar';
@import 'popups';
@import 'footer';
