.sidebar {

  &-social {
    margin: 0 2%;

    &:first-child {
      margin-left: 0; }

    &__img {
      width: 32px;
      height: 32px; } }

  .list__item--socials {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10px 0; } }

