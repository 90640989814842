.loader {
  $loader: &;

  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #111;
    z-index: 1200; }

  &.active {
    #{$loader}__overlay {
      display: block; } }

  &__container {
    width: 70px;
    height: 35px;
    overflow: hidden;
    position: absolute;
    top: calc(50% - 17px);
    left: calc(50% - 35px); }

  &__indicator {
    width: 70px;
    height: 70px;
    border: solid #FFF;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotate 3s ease-in-out infinite;
    transform: rotate(-200deg); } }

@keyframes rotate {
  0% {
    border-width: 10px; }
  25% {
    border-width: 3px; }
  50% {
    transform: rotate(115deg);
    border-width: 10px; }
  75% {
    border-width: 3px; }
  100% {
    border-width: 10px; } }
